<template>
    <div class="hotGood-container">
<!--    <el-scrollbar class="hotGood-container" :native="false">-->
        <div class="analysis-left">
            <div class="gender-distribution eCharts-item">
                <span class="title">性别分布</span>
                <div class="distribution-chart">
                    <div class="chart-icon">
                        <i class="iconfont">&#xe6a6;</i>
                        <i class="iconfont">&#xe6a8;</i>
                    </div>
                    <div class="chart-content">
                        <div class="man" :style="{width: genderAnalysis.female + '%'}"></div>
                        <div class="woman"></div>
                    </div>
                    <div class="legend">
                        <div class="legend-man">
                            <span>男</span>
                            <span>{{(genderAnalysis.female).toFixed(2)}}%</span>
                        </div>
                        <div class="legend-woman">
                            <span>女</span>
                            <span>{{(genderAnalysis.male).toFixed(2)}}%</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="eCharts-item">
                <div class="item-title">
                    <span>年龄分布</span>
                </div>
                <div class="item-content" id="ageDistribution"></div>
            </div>
        </div>
        <div class="class-list">
            <div class="analysis-right">
                <span class="title">地区分布</span>
                <div class="area-distribution">
                    <div class="title">
                        <span>名称</span>
                        <span>占比</span>
                    </div>
                    <div class="content">
                        <div v-if="areaDistribution.length === 0" style="text-align: center; color: #999; margin-top: 20px">暂无数据</div>
                        <div class="area-item" v-for="item in areaDistribution">
                            <span class="area-name">{{item.title}}</span>
                            <el-progress :percentage="item.rate" :show-text="false" :stroke-width="12"></el-progress>
                            <span class="percentage">{{item.value}}%</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!--    </el-scrollbar>-->
    </div>
</template>

<script>
    export default {
        name: "GoodAudience",
        data() {
            return {
                goodsId: Number(this.$route.query.id) || '',
                //性别分析
                genderAnalysis: {
                    female: 0,
                    male: 0,
                },
                //年龄分布
                ageDistribution: null,
                ageData: [],
                //地区分布
                areaDistribution: [],
            }
        },
        mounted() {
            this.getDyPromotionInfo();
            this.grtAgeDistribution();
        },
        methods: {
            getDyPromotionInfo() {
                this.$httpStudent.axiosGetBy(this.$api.goodsFansData, {id: this.goodsId}, (res) => {
                    if (res.code === 200) {
                        let female = 0;
                        this.ageData = res.info.fans_data.birthday_dist
                        this.grtAgeDistribution(this.ageData)
                        this.genderAnalysis.female = res.info.fans_data.gender_dist.female;
                        this.genderAnalysis.male = res.info.fans_data.gender_dist.male;

                        let areaTmp = res.info.fans_data.province_dist;
                        let arrTmp = []
                        for(let i in areaTmp){
                            arrTmp.push({
                                rate: areaTmp[i],
                                title: i,
                            })
                        }
                        if (arrTmp.length > 0) {
                            let sum = arrTmp[0].rate;
                            for (let i in arrTmp) {
                                let obj = {
                                    title: arrTmp[i].title,
                                    rate: Number((arrTmp[i].rate / sum * 100 ).toFixed(2)),
                                    value: Number(arrTmp[i].rate ).toFixed(2)
                                };
                                if (i < 10) {
                                    this.areaDistribution.push(obj);
                                }
                            }
                        }
                        if (!res.info.fans_data.province_dist) {
                            this.areaDistribution = []
                        }
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            grtAgeDistribution(array){
                let ageDistributionChart = this.$echarts.init(document.getElementById('ageDistribution'))
                let resizeTimer = null;
                window.onresize = () => {
                    clearTimeout(resizeTimer);
                    resizeTimer = setTimeout(() => {
                        ageDistributionChart.resize();
                    }, 100)
                }

                let ageArray = []
                if (this.ageData) {
                    for (let item in this.ageData) {
                        ageArray.push(this.ageData[item])
                    }
                } else {
                    // ageArray = ['1', '1', '1', '1', '1', '1']
                    ageArray = ['0', '0', '0', '0', '0', '0']
                }

                ageDistributionChart.setOption({
                    tooltip: {},
                    xAxis: {
                        data: ["18岁以下","18-25岁","26-32岁","33-39岁","40-46岁","46岁以上"]
                    },
                    yAxis: {},
                    grid: {
                        top: 20,
                        left: 40,
                        right: 0,
                        bottom: 40,
                    },
                    color: {
                        type: 'linear',
                        x: 0.5,
                        y: 0.5,
                        r: 0.5,
                        colorStops: [{
                            offset: 1, color: '#25C495' // 0% 处的颜色
                        }, {
                            offset: 0, color: '#039167' // 100% 处的颜色
                        }],
                    },
                    series: [{
                        name: '年龄',
                        type: 'bar',
                        barWidth: '15px',
                        data: ageArray
                    }]
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .hotGood-container {
        width: 100%;
        /*height: calc(100% - 40px);*/
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
        /*padding-bottom: 20px;*/
        /*::v-deep .el-scrollbar__view {*/
        /*    display: flex;*/
        /*    justify-content: space-between;*/
        /*    width: 100%;*/
        /*}*/
        /*::v-deep .el-scrollbar__wrap {*/
        /*    overflow-x: hidden;*/
        /*}*/
        .analysis-left {
            display: flex;
            flex-direction: column;
            width: 50%;
            height: 100%;
            border-right: 1px solid #EAEAEA;
            /*padding: 0 20px;*/
        }
        .eCharts-item {
            margin-top: 30px;
            padding: 0 20px;
            &:first-child {
                margin-top: 0;
            }
            .item-title {
                font-size: 16px;
                display: flex;
                justify-content: space-between;
                .toggle-day {
                    .day {
                        cursor: pointer;
                        color: #999;
                        &.current, &:hover {
                            color: #42D7AB;
                        }
                    }
                }
            }
            .item-content {
                /*background: #ffa72a;*/
                margin-top: 10px;
                width: 100%;
                height: 300px;
            }
        }
    }
    .gender-distribution {
        display: flex;
        flex-direction: column;
        /*margin-bottom: 50px;*/
        .title {
            font-size: 14px;
            font-weight: 400;
            color: rgba(51, 51, 51, 1);
        }
        .distribution-chart {
            .chart-icon {
                display: flex;
                justify-content: space-between;
                color: #34aa7f;
                margin-top: 10px;
                & i {
                    font-size: 24px;
                }
            }
            .chart-content {
                width: 100%;
                display: flex;
                justify-content: space-between;
                height: 20px;
                margin-top: 10px;
                .man {
                    background-color: #34aa7f;
                }
                .woman {
                    flex: 1;
                    background-color: #46dbab;
                }
            }
            .legend {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: rgba(102, 102, 102, 1);
                display: flex;
                justify-content: space-between;
                margin-top: 20px;

                .legend-man {
                    display: flex;
                    flex-direction: column;
                }
                .legend-woman {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }
    .class-list {
        width: 50%;
        height: 1%;
        flex: 1;
        .analysis-right {
            display: flex;
            flex-direction: column;
            /*width: 50%;*/
            height: 100%;
            padding: 0 20px;

            .title {
                display: flex;
                justify-content: space-between;
                margin-bottom: 15px;
                padding-right: 5px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(51, 51, 51, 1);
            }
            .content {
                flex: 1;
                overflow: auto;
                padding-right: 5px;
            }
            .area-distribution {
                flex: 1;
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                height: calc(100% - 66px);
                .area-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 15px;
                    .area-name {
                        width: 50px;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    .percentage {
                        width: 60px;
                        text-align: right;
                    }
                    ::v-deep .el-progress {
                        width: 1%;
                        flex: 1;
                        padding: 0 10px;
                    }
                    ::v-deep .el-progress-bar__inner {
                        background: linear-gradient(45deg, #ffa72a, #f8b95f 98%);
                    }
                }
            }
        }
    }
</style>