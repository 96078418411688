<template>
    <div class="hotGood-container">
<!--    <el-scrollbar class="hotGood-container" :native="false">-->
        <div class="eCharts-item">
            <div class="item-title">
                <span>热推达人趋势</span>
                <span class="toggle-day">
                    <span class="day" :class="{current: saleCurrent === 'sale7'}" @click="toggleSale('sale7')">近7天</span>
                    |
                    <span class="day" :class="{current: saleCurrent === 'sale30'}" @click="toggleSale('sale30')">30天</span>
                </span>
            </div>
            <div class="item-content" id="salesDayIncrement"></div>
        </div>
        <div class="eCharts-item">
            <div class="item-title">
                <span>每日视频趋势</span>
                <span class="toggle-day">
                    <span class="day" :class="{current: viewCurrent === 'view7'}" @click="toggleView('view7')">近7天</span>
                    |
                    <span class="day" :class="{current: viewCurrent === 'view30'}" @click="toggleView('view30')">30天</span>
                </span>
            </div>
            <div class="item-content" id="viewsDayIncrement"></div>
        </div>
        <div class="eCharts-item">
            <div class="item-title">
                <span>每日直播趋势</span>
                <span class="toggle-day">
                    <span class="day" :class="{current: inDeCurrent === 'inDe7'}" @click="toggleInDe('inDe7')">近7天</span>
                    |
                    <span class="day" :class="{current: inDeCurrent === 'inDe30'}" @click="toggleInDe('inDe30')">30天</span>
                </span>
            </div>
            <div class="item-content" id="increaseOrDecrease"></div>
        </div>
        <div class="eCharts-item">
            <div class="item-title">
                <span>抖音订单数趋势</span>
                <span class="toggle-day">
                    <span class="day" :class="{current: netCurrent === 'net7'}" @click="toggleNet('net7')">近7天</span>
                    |
                    <span class="day" :class="{current: netCurrent === 'net30'}" @click="toggleNet('net30')">30天</span>
                </span>
            </div>
            <div class="item-content" id="netSales"></div>
        </div>
<!--    </el-scrollbar>-->
    </div>
</template>

<script>
    export default {
        data() {
            return {
                goodsId: Number(this.$route.query.id) || '',
                saleCurrent: 'sale7',
                viewCurrent: 'view7',
                netCurrent: 'net7',
                inDeCurrent: 'inDe7',
                salesDayIncrement: null,
                viewsDayIncrement: null,
                netSales: null,
                increaseOrDecrease: null,
            }
        },
        mounted() {
            this.salesDayIncrement = this.$echarts.init(document.getElementById('salesDayIncrement'));
            this.viewsDayIncrement = this.$echarts.init(document.getElementById('viewsDayIncrement'));
            this.netSales = this.$echarts.init(document.getElementById('netSales'));
            this.increaseOrDecrease = this.$echarts.init(document.getElementById('increaseOrDecrease'));
            this.setSalesDayIncrement();
            this.setViewsDayIncrement();
            this.setIncreaseOrDecrease();
            this.setNetSales();

            let resizeTimer = null;
            window.onresize = () => {
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(() => {
                    this.salesDayIncrement.resize()
                    this.viewsDayIncrement.resize()
                    this.netSales.resize()
                    this.increaseOrDecrease.resize()
                }, 100)
            }
        },
        methods: {
            toggleSale(type) {
                this.saleCurrent = type
                this.setSalesDayIncrement()
            },
            toggleView(type) {
                this.viewCurrent = type
                this.setViewsDayIncrement()
            },
            toggleInDe(type) {
                this.inDeCurrent = type
                this.setIncreaseOrDecrease()
            },
            toggleNet(type) {
                this.netCurrent = type
                this.setNetSales()
            },
            setEChartsOption(item, xData, yData, title) {
                item.setOption({
                    xAxis: {
                        type: 'category',
                        data: xData,
                        axisLabel: {
                            textStyle: {
                                color: '#333'
                            },
                            rotate: 40,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#EAEAEA'
                            }
                        },
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            textStyle: {
                                color: '#333'
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: 'transparent'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#EAEAEA'
                            }
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        }
                    },
                    grid: {
                        top: 20,
                        left: 60,
                        right: 0,
                        bottom: 60,
                    },
                    series: [{
                        name: title,
                        type: 'line',
                        data: yData,
                        itemStyle: {
                            normal: {
                                color: "#42D7AB",
                            }
                        },
                        areaStyle: {
                            color: '#cff2e6'
                        },
                    }]
                });
            },
            setSalesDayIncrement() {
                let xData = []
                let yData = []
                let listArr = []
                this.$httpStudent.axiosGetBy(this.$api.tbGoodsHot, {id: this.goodsId}, (res) => {
                    if (res.code === 200) {
                        if (this.saleCurrent === 'sale7') {
                            listArr = res.info.video_base_analysis_7
                            for (let i = 0; i < listArr.length; i++) {
                                xData.push(listArr[i].time)
                                yData.push(listArr[i].retui_data)
                            }
                        } else {
                            listArr = res.info.video_base_analysis_30
                            for (let i = 0; i < listArr.length; i++) {
                                xData.push(listArr[i].time)
                                yData.push(listArr[i].retui_data)
                            }
                        }
                        this.setEChartsOption(this.salesDayIncrement, xData, yData, '热推达人趋势')
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            setViewsDayIncrement() {
                let xData = []
                let yData = []
                let listArr = []
                this.$httpStudent.axiosGetBy(this.$api.tbGoodsHot, {id: this.goodsId}, (res) => {
                    if (res.code === 200) {
                        if (this.viewCurrent === 'view7') {
                            listArr = res.info.video_base_analysis_7
                            for (let i = 0; i < listArr.length; i++) {
                                xData.push(listArr[i].time)
                                yData.push(listArr[i].video_data)
                            }
                        } else {
                            listArr = res.info.video_base_analysis_30
                            for (let i = 0; i < listArr.length; i++) {
                                xData.push(listArr[i].time)
                                yData.push(listArr[i].video_data)
                            }
                        }
                        this.setEChartsOption(this.viewsDayIncrement, xData, yData, '每日视频趋势')
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            setIncreaseOrDecrease() {
                let xData = []
                let yData = []
                let listArr = []
                this.$httpStudent.axiosGetBy(this.$api.tbGoodsHot, {id: this.goodsId}, (res) => {
                    if (res.code === 200) {
                        if (this.inDeCurrent === 'inDe7') {
                            listArr = res.info.video_base_analysis_7
                            for (let i = 0; i < listArr.length; i++) {
                                xData.push(listArr[i].time)
                                yData.push(listArr[i].zhibo_data)
                            }
                        } else {
                            listArr = res.info.video_base_analysis_30
                            for (let i = 0; i < listArr.length; i++) {
                                xData.push(listArr[i].time)
                                yData.push(listArr[i].zhibo_data)
                            }
                        }
                        this.setEChartsOption(this.increaseOrDecrease, xData, yData, '每日直播趋势')
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            setNetSales() {
                let xData = []
                let yData = []
                let listArr = []
                this.$httpStudent.axiosGetBy(this.$api.tbGoodsHot, {id: this.goodsId}, (res) => {
                    if (res.code === 200) {
                        if (this.netCurrent === 'net7') {
                            listArr = res.info.video_base_analysis_7
                            for (let i = 0; i < listArr.length; i++) {
                                xData.push(listArr[i].time)
                                yData.push(listArr[i].order_count)
                            }
                        } else {
                            listArr = res.info.video_base_analysis_30
                            for (let i = 0; i < listArr.length; i++) {
                                xData.push(listArr[i].time)
                                yData.push(listArr[i].order_count)
                            }
                        }
                        this.setEChartsOption(this.netSales, xData, yData, '抖音订单数趋势')
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .hotGood-container {
        /*height: calc(100% - 40px);*/
        margin: 20px 0;
        /*::v-deep .el-scrollbar__wrap {*/
        /*    overflow-x: hidden;*/
        /*}*/
        .eCharts-item {
            margin-top: 30px;
            padding: 0 20px;
            &:first-child {
                margin-top: 0;
            }
            .item-title {
                font-size: 16px;
                display: flex;
                justify-content: space-between;
                .toggle-day {
                    .day {
                        cursor: pointer;
                        color: #999;
                        &.current, &:hover {
                            color: #42D7AB;
                        }
                    }
                }
            }
            .item-content {
                /*background: #ffa72a;*/
                margin-top: 10px;
                width: 100%;
                height: 340px;
            }
        }
    }
</style>