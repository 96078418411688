<template>
    <div class="goodDetail-right">
        <div class="header">帮助中心</div>
        <el-scrollbar class="help-list" :native="false">
            <div class="h-item" v-for="(helpItem, helpIndex) in helpList" :key="helpIndex" @click="viewHelpDetail(helpItem)">
                <span class="index">{{helpIndex + 1}}</span>
                <p class="title text-overflow-2">{{helpItem.title}}</p>
            </div>
            <div v-if="helpList.length === 0" style="text-align: center;">
                <span>暂无数据</span>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    export default {
        name: "RightHelpList",
        data() {
            return {
                helpList: [],
            }
        },
        mounted() {
            this.getHelpList();
        },
        methods: {
            getHelpList() {
                let param = {}
                if (this.$route.path === '/student/hot/authorVideoDetail') {
                    param.help_id = 1;
                }
                if (this.$route.path === '/student/hot/videoDetail') {
                    param.help_id = 2;
                }
                if (this.$route.path === '/student/ds/video/videodetail') {
                    param.help_id = 3;
                }
                if (this.$route.path === '/student/dy/goodDetail') {
                    param.help_id = 4;
                }
                if (this.$route.path === '/student/tb/goodDetail') {
                    param.help_id = 5;
                }
                if (this.$route.path === '/student/ds/video/details') {
                    param.help_id = 6;
                }
                this.$httpStudent.axiosGetBy(this.$api.help, param, (res) => {
                    if (res.code === 200) {
                        this.helpList = res.data.data;
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //
            viewHelpDetail(item) {
                let routeUrl = this.$router.resolve({
                    path: '/student/help/detail',
                    query: {
                        id: item.id,
                    }
                });
                window.open(routeUrl .href, '_blank');
                // this.$router.push({
                //     path: '/student/help/detail',
                //     query: {
                //         id: item.id
                //     }
                // })
            }
        }
    }
</script>

<style scoped lang="scss">
    .goodDetail-right {
        width: 360px;
        background: #fff;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        /*min-height: calc(100vh - 100px);*/
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .header {
            padding: 20px 20px 0;
            text-align: center;
            font-size: 16px;
        }
        .help-list {
            height: 1%;
            flex: 1;
            margin: 20px 0;
            .h-item {
                display: flex;
                padding: 0 20px;
                margin-top: 10px;
                color: #333;
                cursor: pointer;
                &:first-child {
                    margin-top: 0;
                }
                .index {
                    min-width: 20px;
                    height: 20px;
                    line-height: 20px;
                    font-size: 12px;
                    color: #fff;
                    background: #0000FF;
                    text-align: center;
                    border-radius: 50%;
                }
                .title {
                    width: 1%;
                    flex: 1;
                    margin-left: 10px;
                    margin-top: 0;
                    margin-bottom: 0;
                }
                &:hover {
                    color: #1E33E3;
                }
            }
        }
    }
</style>